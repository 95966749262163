<template>
    <div class="con">
        <div class="container-preview mt-5 d-none d-lg-block"></div>
        <div class="row d-none d-lg-block">
            <div class="col-sm-12 clinicCard-Box"></div>
        </div>
        <div class="d-block d-lg-none">
        <div class="row" id="page-content-wrapper">
            <div class="col-10 col-sm-9 col-lg-10 mb-0">
        <p class="clinic-name" style="margin-left: 20px;">{{ this.clinicName }}</p>
      </div>
      <div class="col-2 col-sm-3 col-lg-2 mb-0 ">
        <p class="closeButton" @click="clinicPreview()"><font-awesome-icon :icon="['fas','times']" /></p>
      </div>
        </div>
        <hr class="horizonal-lineTag">
      </div>
        <div class="box-shadow content p-4 searchclinicCard">
            <div class="d-none d-lg-block">
        <div class="row ">
            <div class="col-10 col-sm-9 col-lg-10 mb-0">
        <p class="clinic-name" style="margin-left: 20px;">{{ this.clinicName }}</p>
      </div>
      <div class="col-2 col-sm-3 col-lg-2 mb-0 ">
        <p class="closeButton mt-0" @click="searchclinic()"><font-awesome-icon :icon="['fas','times']" /></p>
      </div>
        </div>
        <hr class="horizonal-lineTag">
        </div>
            <div class="row details-Clinic">
                <div class="col-6 mt-3">
                    <div class="form-group">
                        <img src="~@/assets/img/images/doctor.png" id="preview_img" class="preview-doctor-img">
                    </div>
                    <label for="" class="doctor-name text-align text-center">{{ userPayload.firstName }} {{
                    userPayload.lastName }}</label>
                </div>

                <!--FOR DESKTOP -->
                <div class="col-6 mt-3 d-none d-lg-block">
                    <h4 class="mt-3 clinicDoctorDetails ">Pincodes around which home consulation is offered
                        <span class="inputContent" style="color: gray;">
                            <div v-for="area in homeConsultationPincode" :key="area">
                                <div class="row" v-if="area.home_id" > 
                    <div class="col-lg-4 col-md-4 col-12 mt-1 mb-1">
                        {{ area.home_pincode }}
                    </div>
                    </div>
                    </div>
                     </span>
                    </h4>
                </div>
                
                <!-- FOR MOBILE -->
                <div class="col-12 d-block d-lg-none">
                <h4 class="mt-2 mb-2 clinicDoctorDetails ">Pincodes around which home consulation is offered
                        <span class="inputContent" style="color: gray;">
                            <div v-for="area in homeConsultationPincode" :key="area">
                                <div class="row" v-if="area.home_id" > 
                <div class="col-lg-4 col-md-4 col-12 mt-1 mb-1">
                    {{ area.home_pincode }}
                </div>
                </div>   
                    </div></span>
                    </h4>
                </div>
            </div>

            <!-- FOR DESKTOP -->
            <div class="d-none d-lg-block">
                <div class="row">
                    <div class="col-6">
                        <h4 class="clinicDoctorDetails mt-3">Educational qualification : <span class="doctorDetails">{{
                            userPayload.educational }}</span></h4>
                    </div>
                    <div class="col-6">
                        <div>
                            <button type="button" id="btn_doctor_join" class="btn btn-blue-color text-white"
                                v-if="!this.openCont" @click="openContact()">View
                                contact details</button>

                        </div>
                    </div>
                </div>
            </div>
            <!-- FOR MOBILE -->
            <div class="d-block d-lg-none">

                <h4 class="clinicDoctorDetails ">Educational qualification : <span class="doctorDetails">{{
                    userPayload.educational }}</span></h4>
                <div class="mt-3">
                    <button type="button" id="btn_doctor_join" class="viewcontactButton btn btn-blue-color text-white"
                         @click="openContact()">View
                        contact details</button>
                </div>
            </div>
            <hr class="horizonal-lineTag">
            <div class="row">
                <!-- FOR DESKTOP  -->
                <div class="col-12 doctor-info d-none d-lg-block">
                    <h4>Consult for</h4>
                    <p class="consultations">{{ practiceAreas }}</p>
                    <h4 class="mt-3">Consultation offered :</h4>
                    <p class="consultations">{{ offerConsultations }}</p>
                    <h4 class="" v-if="userPayload.information !=null">Additional information : <span class="doctorDetails">
                        {{ userPayload.information }}</span>
                        </h4>
                    
                </div>


                <!-- FOR MOBILE  -->
                <div class="col-12 doctor-info d-block d-lg-none ">
                    <h4>Consult for</h4>
                    <p class="consultations">{{ practiceAreas }}</p>
                    <h4 class="mt-3">Consultation offered :</h4>
                    <p class="consultations">{{ offerConsultations }}</p>
                    <h4 class="" v-if="userPayload.information !=null">Additional information : <span class="doctorDetails">
                        {{ userPayload.information }}</span>
                        </h4>
  
                </div>
                    <h4 class="" v-if="getClinicPincode.length">Also :               
                    <span   class="doctorDetails" style="color: gray;">
                        <span class="contact-details" v-for="index in getClinicPincode" :key="index" @click="sign(index)"><a href="">@Clinic {{
                            index.pincode }}</a></span></span></h4>
            </div>
            <hr class="horizonal-lineTag">
        </div>
    </div>
</template>
<script>
import axios from 'axios';  
export default {
    components: {
    },
    data() {
        return {
            homeConsultationPincode: [],
            homeConsultation:{},
            userPayload: [],
            userRecords: [],
            homeConsult: [],
            getClinicPincode: [],
            clinicData: [],
            openCont: false,
            issign: false,
            clinicId: {},
            doctorId: null,
            offerConsultations: "",
            practiceAreas:'',
            docData:{}
        }
    },
    created: function () {
        const ClinicID = this.$route.query
        this.clinicId = ClinicID
        this.getConsultation();
        this.getDoctor();
    },
    methods: {
        getDoctor(){
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            axios
        .get(`${BASE_API_URL}/hcps/${this.clinicId.hcpId}/one`)
        .then((response)=>{
            this.docData= response.data
            document.title=`Home consultation - ${this.docData.firstName} ${this.docData.lastName}`;
        })
        },
        sign(index) {
      const clinicID = index.id
      const doctorId =index.hcps_id.id
      window.open(`/preview/clinic?hcpId=${doctorId}&clinicId=${clinicID}`)
    },
        
        async searchclinic() {
            window.scrollTo(0, 0);
            this.$router.push(`/public/searchclinic`);
        },
        signupPopupStatus(event) {
            if (event) {
                // this.issign = false;
            }
        },
        joinMyCommunity() {
            // this.issign = true;
        },
        async getConsultation() {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
              axios
                .get(`${BASE_API_URL}/home-consultation`)
                .then((response) => {
                    this.homeConsult = response.data;
                     this.homeConsult.map(async (datas) => {
                            if (datas.id === parseInt(this.clinicId.homeId)) {
                                this.doctorId = datas?.hcps_id?.id;
                                this.clinicName = datas.clinic_name;
                                this.homeConsultation={
                                    clinicName:datas.clinic_name,
                                    doctorId:datas.hcps_id,
                                    id:datas.id,
                                    clinicMobile:datas.mobile,
    
                                }
                            }
                        
                    })
                })
            await axios
                .get(`${BASE_API_URL}/clinic-consultation`)
                .then(async (response) => {
  this.getClinicPincode = response.data.filter(item => item?.hcps_id?.id === this.doctorId);
                })
                axios
        .get(`${BASE_API_URL}/home-consultation-pincode`)
        .then((response) => {
            this.homeConsultationPincode = response.data.filter(item => item?.home_id?.hcps_id?.id === this.doctorId);
            });
            axios
                .get(`${BASE_API_URL}/hcps/${this.doctorId}/one`)
                .then((response) => {
                    this.userPayload = response.data;
                    const consultationArray = this.userPayload.Doctorconsultaion || [];
                    this.offerConsultations = consultationArray.map(item => item.consultation).join(', ');
                    const practiceAreasArray = this.userPayload.practiceId ;
                    this.practiceAreas = practiceAreasArray.map(item => item.practiceArea).join(', ');
                })
        },
        openContact() {
            const searchClinicPayload={
                homeClinic : JSON.stringify(this.homeConsultation),
                homeClinicPincode:JSON.stringify(this.homeConsultationPincode),
                routeName: 'previewPage-home',
                card:'public',
                searchClinicId:JSON.stringify(1)
            }
            window.scrollTo(0, 0);
            this.$router.push({ path: '/signup', query: searchClinicPayload });
        }
    },
}
</script>
<style>
.inputContent {
    /* font-size: 16px; */
    color: gray;
}

.text-green-color{
  font-size:20px;
  color: #00979e;
  cursor: pointer;
}

hr.horizonal-lineTag {
    margin: 0px;
}

.clinic-name {
    font-size: 25px;
    font-weight: 550;
}

.details-Clinic {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.clinicCard-Box {
    padding: 10px;
    margin-top: 10px
}

p.consultations {
    font-size: 18px;
    color: gray;
}

span.doctorDetails {
    font-size: 18px;
    color: gray;
}

span.contact-details {
    font-size: 18px;
    color: gray;
    margin: 10px;
}

.searchclinicCard {
    background-color: #fff;
}

.backbtnforPublic {
    width: 100px;
    display: flex;
}

.clinicBackButton {
    background-color: #00979e;
    color: #fff;
}

.box-shadow.content.p-4 {
    width: 65%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}

/* html {
    background-color: rgb(243, 242, 239);
} */

.con {
    background-color: rgb(243, 242, 239);
}

.container-preview {
    /* max-width: 1170px; */
    max-width: 80%;
    margin: auto;
}

h4 {
    font-size: 20px;
}

.doctor-info h4 {
    margin-top: 20px;
}

.doctor-name {
    margin-top: 5px;
    font-size: 18px;
    color: #000;
}

@media screen and (max-width:992px) {

    .box-shadow.content.p-4 {
        width: 100%;
        margin-top: 0px;
        box-shadow: none;
    }
    .clinic-name {
        margin-top: 1rem;
    }
}

@media screen and (max-width:576px) {
    .doctor-name {
        font-size: 14px;
        margin-top: 0px;
    }

    .clinic-name {
    margin-left: 20px;
    margin-top: 1rem;
    color: #000;
    font-weight: 550;
    font-size: 21px;
    }

    h4.clinicDoctorDetails {
        font-size: 16px;
    }

    p.consultations {
        font-size: 16px;
        margin-top: 10px;
    }

    .doctor-info h4 {
        margin-top: 10px;
        font-size: 16px;
    }

    span.doctorDetails {
        font-size: 16px;
    }

    .details-Clinic {
        margin-top: 0.5rem;
    }

    .inputContent {
        font-size: 16px;
        color: gray;
    }
}
</style>
